import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { GOOGLE_LOGIN_URL, REDIRECT_URL } from './../../../config/constant';
import InputField from '../../elements/InputField';
import Captcha from "../../common/Captcha";
class GoogleLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showotpFrm: false,
            errors: {},
            redirect: 0,
            reloadCaptcha: 1,
            fields: { mobileCountryCode: "", captchaVal: "", captchaId: '' },
            otpTimer: 0,
            inputFieldCaptcha: { captcha: "" },
        }
    }
    componentWillReceiveProps = (nextProps) => {
        this.setState({ showotpFrm: nextProps.showotpFrm });
        if (this.state.showotpFrm != nextProps.showotpFrm && nextProps.showotpFrm) {
            this.setState({ otpTimer: 60 })
        }
    }
    componentDidMount = () => {
        let self = this
        this.myInterval = setInterval(() => {
            self.setState(({ otpTimer }) => ({
                otpTimer: otpTimer - 1
            }))
        }, 1000)
    }
    handleChange = event =>{
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }
    handleGooglelogin = () => {
        window.location = GOOGLE_LOGIN_URL + REDIRECT_URL
    }
    captchaFiledHandler = (childData) => {
        let captcahId = childData.captchaData.data.value;
        let tempErrors = this.state.errors;
        let tempInputFieldCaptcha = this.state.inputFieldCaptcha;
        if (captcahId) {
            tempErrors['captchaErr'] = "";
            tempInputFieldCaptcha['captcha'] = childData.captchaVal;
        }
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(childData);
        }
        this.setState({ tempErrors });
    }
    render() {
        if (this.state.redirect == 1) {
            return (<Redirect to={'/dashboard'} />);
        }
        const { errors } = this.state;
        return (
            <div id="otp-forms">
                {this.state.showotpFrm === false ?
                    <button className="google-sign-in-button" onClick={() => this.handleGooglelogin()}>Sign in with Google 🚀 </button>
                    :
                    <div className="col-sm-12">
                        <InputField
                            inputProps={{
                                id: "otpValue",
                                type: "number",
                                name: "otpValue",
                                label: this.props.t('joinus.otp'),
                                autoFocus: true,
                                dataerror: errors.otpValue
                            }}
                            onChange={(e)=>this.handleChange(e)}
                        />
                        {this.state.otpTimer > 0 ?
                            <span className="otp-time">{"00:" + (this.state.otpTimer > 9 ? this.state.otpTimer : "0" + this.state.otpTimer)}</span> : 
                            <span className="otp-time" style={{ cursor: "pointer" }} onClick={(event) => this.props.handleResend(event)}>Resend</span>
                        }
                        <Captcha onKeyPressCaptcha={this.captchaFiledHandler} errField={this.state.errors} inputField={this.state.inputFieldCaptcha} reloadCaptcha={this.state.reloadCaptcha} />
                        <div className="form-group">
                            <button className="btn btn-primary btn-lg btn-block" id="sub-login" name="email_login" type="submit" onClick={this.props.submitLoginForm}>{this.props.t('login.sign_in')}</button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn
    };
}
const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(GoogleLogin));